import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { type useUserQuery } from "./__generated__/useUserQuery.graphql"

const query = graphql`
  query useUserQuery {
    users {
      id
      email
      role {
        name
      }
      firstName
      lastName
      createdAt
      lastSeen
      deletedAt
    }
  }
`

const useUserQuery = () => {
  const { users } = useLazyLoadQuery<useUserQuery>(query, {})

  return users
}

export { useUserQuery }
