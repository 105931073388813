import { Outlet } from "react-router-dom"
import { Sidebar } from "./sidebar"
import { Topbar } from "./topbar"

const DefaultLayout = () => {
  return (
    <div className="grid h-dvh w-dvw md:grid-cols-[275px_1fr]">
      <Sidebar />
      <div className="overflow-hidden">
        <Topbar />
        <div className="h-full">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export { DefaultLayout }
